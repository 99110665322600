import axios from 'axios';

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false, // This is the default
    timeout: 60000
});

export const getStatistics = async () => {
    const statistics = await apiClient.post('/announces', { lottoMaster: 1 });
    return statistics;
};

export const getStatisticToday = async () => {
    const statisticToday = await apiClient.post('/announce', { lottoMaster: 1 });
    return statisticToday;
}

export const getPeriods = async (date, type, lottoMaster) => {
    const period = await apiClient.get('/period?announce=' + date + '&type=' + type + '&lottoMaster=' + lottoMaster);
    return period;
}