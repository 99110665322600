import './App.css';
import React, { Component } from 'react';
import thaivipImage from './assets/images/header.png';
import dateTimeImage from './assets/images/datetime.png';
import moment from 'moment';
import { getPeriods, getStatisticToday, getStatistics } from './services/services';

const beforeStartDownAnnounceDate = moment().format('YYYY-MM-DD 15:45:01');
const afterStartDownAnnounceDate = moment().format('YYYY-MM-DD 15:46:59');
const startDownAnnounceDate = moment().format('YYYY-MM-DD 15:47:00');
const endDownAnnouneDate = moment().format('YYYY-MM-DD 15:49:59');
const downAnnounceDate = moment().format('YYYY-MM-DD 15:50:00');

const beforeStartUpAnnounceDate = moment().format('YYYY-MM-DD 15:50:01');
const afterStartUpAnnounceDate = moment().format('YYYY-MM-DD 15:52:59');
const startUpAnnounceDate = moment().format('YYYY-MM-DD 15:53:00');
const endUpAnnouneDate = moment().format('YYYY-MM-DD 15:59:59');
const upAnnounceDate = moment().format('YYYY-MM-DD 16:00:00');

const randomNumber = () => {
  const rand = Math.floor(Math.random() * 10);
  return rand + '.png';
}

const getLoading = () => {
  return 'loading.gif';
  // return '1.png';
}

const toThaiDateString = (date) => {
  const monthNames = [
    "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
    "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.",
    "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
  ];

  const dayNames = [
    'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'
  ];

  // moment().days
  let year = date.year() + 543;
  let month = monthNames[date.month()];
  let numOfDay = date.date();
  let day = dayNames[date.days()];

  return `${day} ${numOfDay} ${month} ${year} `;
}

class App extends Component {

  _isMounted = false;
  timer = null;
  downTimer = null;
  upTimer = null;

  state = {
    beforeStartDown: false,
    beforeStartUp: false,
    todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    announceStartDate: moment().format('YYYY-MM-DD 15:45:00'),
    announceEndDate: moment().format('YYYY-MM-DD 16:00:00'),
    isMobile: false,
    lImage: 60,
    onePrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading(),
      six: getLoading(),
    },
    twoPrizes: {
      // one: getLoading(),
      // two: getLoading(),
      // three: getLoading(),
      // four: getLoading(),
      five: getLoading(),
      six: getLoading(),
    },
    upThree: "-",
    upTwo: "-",
    downTwo: "-",
    oldAnnounce: false,
    newAnnounce: false,
    downAnnounce: false,
    oldUpAnnounce: false,
    downRelease: false,
    upAnnounce: false,
    statistics: []
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.downTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllStatistics();
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();

    this.setTimer();
  }

  getAllStatistics = async () => {
    const response = await getStatistics();
    this.setState({
      statistics: response.data
    })
  }

  setTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
        this.announce();
      }, 1000);
    }
  }

  announce = () => {
    const { announceStartDate, announceEndDate, todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
      this.beforeAnnonce();
    } else if (
      moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
      && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
    ) {
      this.setData();
    } else {
      this.afterAnnounce();
    }
  }

  beforeAnnonce = async () => {
    const { todayDate, oldAnnounce } = this.state;
    if (!oldAnnounce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 16:00:00");
      const period = await getPeriods(dateNow, 'both', '1');
      const up = period.data.up6;
      const down = period.data.down6;

      this.setState({
        onePrizes: {
          one: up[0] + '.png',
          two: up[1] + '.png',
          three: up[2] + '.png',
          four: up[3] + '.png',
          five: up[4] + '.png',
          six: up[5] + '.png',
        },
        twoPrizes: {
          // one: down[0] + '.png',
          // two: down[1] + '.png',
          // three: down[2] + '.png',
          // four: down[3] + '.png',
          five: down[4] + '.png',
          six: down[5] + '.png',
        },
        upThree: up.substr(3, up.length),
        upTwo: up.substr(4, up.length),
        downTwo: down.substr(4, down.length),
        oldAnnounce: true
      })
    }
  }

  afterAnnounce = async () => {
    const { newAnnounce } = this.state;
    if (!newAnnounce) {
      const statisticToday = await getStatisticToday();

      const up = statisticToday.data.up6;
      const down = statisticToday.data.down6;

      this.setState({
        onePrizes: {
          one: up[0] + '.png',
          two: up[1] + '.png',
          three: up[2] + '.png',
          four: up[3] + '.png',
          five: up[4] + '.png',
          six: up[5] + '.png',
        },
        twoPrizes: {
          // one: down[0] + '.png',
          // two: down[1] + '.png',
          // three: down[2] + '.png',
          // four: down[3] + '.png',
          five: down[4] + '.png',
          six: down[5] + '.png',
        },
        upThree: up.substr(3, up.length),
        upTwo: up.substr(4, up.length),
        downTwo: down.substr(4, down.length),
        newAnnounce: true
      })
    }

  }

  setData = async () => {
    const { todayDate, beforeStartDown, beforeStartUp, downAnnounce, oldUpAnnounce, downRelease, upAnnounce } = this.state;

    // --------------------- *** DOWN *** -------------------------
    // before start down
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(afterStartDownAnnounceDate).toDate().getTime()) {
      if (!beforeStartDown) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          twoPrizes: {
            // one: getLoading(),
            // two: getLoading(),
            // three: getLoading(),
            // four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          upThree: "-",
          upTwo: "-",
          downTwo: "-",
          beforeStartDown: true
        })
      }
    }

    // down random number started
    if (moment(todayDate).toDate().getTime() >= moment(startDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(endDownAnnouneDate).toDate().getTime()) {
      if (!this.downTimer) {
        this.downTimer = setInterval(() => {
          this.setState({
            twoPrizes: {
              // one: randomNumber(),
              // two: randomNumber(),
              // three: randomNumber(),
              // four: randomNumber(),
              five: randomNumber(),
              six: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // down announce
    if (moment(todayDate).toDate().getTime() === moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // call api and show down
      if (!downAnnounce) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 16:00:00");
        const period = await getPeriods(dateNow, 'down', '1');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            // one: down[0] + '.png',
            // two: down[1] + '.png',
            // three: down[2] + '.png',
            // four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          downTwo: down.substr(4, down.length),
          downAnnounce: true
        });
      }
    }

    if (moment(todayDate).toDate().getTime() > moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // show release down
      if (!downRelease) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 16:00:00");
        const period = await getPeriods(dateNow, 'down', '1');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            // one: down[0] + '.png',
            // two: down[1] + '.png',
            // three: down[2] + '.png',
            // four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          downTwo: down.substr(4, down.length),
          downRelease: true
        });
      }
    }

    // --------------------- *** UP *** -------------------------
    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      // show old up
      if (!oldUpAnnounce) {
        const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 16:00:00");
        const period = await getPeriods(dateNow, 'up', '1');
        const up = period.data.up6;

        this.setState({
          onePrizes: {
            // one: up[0] + '.png',
            // two: up[1] + '.png',
            // three: up[2] + '.png',
            // four: up[3] + '.png',
            // five: up[4] + '.png',
            // six: up[5] + '.png',
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          oldUpAnnounce: true
        });
      }

    }

    // before start up
    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          beforeStartUp: true
        })
      }
    }

    // up random number started
    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (!this.upTimer) {
        this.upTimer = setInterval(() => {
          this.setState({
            onePrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber(),
              six: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // up announce
    if (moment(todayDate).toDate().getTime() === moment(upAnnounceDate).toDate().getTime()) {
      if (this.upTimer) clearInterval(this.upTimer);

      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await getStatisticToday();

        const up = statisticToday.data.up6;
        const down = statisticToday.data.down6;

        this.setState({
          onePrizes: {
            one: up[0] + '.png',
            two: up[1] + '.png',
            three: up[2] + '.png',
            four: up[3] + '.png',
            five: up[4] + '.png',
            six: up[5] + '.png',
          },
          twoPrizes: {
            // one: down[0] + '.png',
            // two: down[1] + '.png',
            // three: down[2] + '.png',
            // four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          upThree: up.substr(3, up.length),
          upTwo: up.substr(4, up.length),
          downTwo: down.substr(4, down.length),
          upAnnounce: true
        })
      }
    }
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 470) {
      this.setState({ isMobile: true, lImage: 40 });
    }
    else {
      this.setState({ isMobile: false, lImage: 60 });
    }
  }

  renderDate = () => {
    const { todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime()) {
      return toThaiDateString(moment());
    }

    return toThaiDateString(moment().add(-1, 'd'));
  }

  render() {

    const { isMobile, lImage, onePrizes, twoPrizes, upThree, upTwo, downTwo } = this.state;

    return (
      <div className="container-fluid text-center bg-image">
        <img src={thaivipImage} className="img-fluid content" style={{ height: 250 }} alt="thai vip image" />

        <div className="date-time">
          <img src={dateTimeImage} className="img-fluid" style={{ marginTop: -35, height: 120 }} alt="date time vip image" />
          <p className="text-over-img">ผลรางวัล วันที่</p>
          <p className="date-time-over-img" style={{ fontSize: isMobile ? 20 : 22, marginTop: isMobile ? -4 : -3, marginLeft: -8 }}>{this.renderDate()}</p>
        </div>

        <h3 style={{ color: 'white', marginTop: -40, marginBottom: 0, fontSize: 30 }}><b>รางวัลที่ 1</b></h3>
        <div className="ones-prize" style={{ width: isMobile ? '100%' : 'fit-content', justifyContent: 'center' }}>
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + onePrizes.one)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + onePrizes.two)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + onePrizes.three)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + onePrizes.four)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + onePrizes.five)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            src={require('./assets/images/' + onePrizes.six)}
            width={lImage}
            height={lImage}
            alt='number'
          />
        </div>

        <h3 style={{ color: 'white', marginTop: 0, marginBottom: 0, fontSize: 30 }}><b>เลขท้าย 2 ตัว</b></h3>
        <div className="two-prize" style={{ width: isMobile ? '100%' : '430px' }}>
          {/* <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + twoPrizes.one)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + twoPrizes.two)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + twoPrizes.three)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + twoPrizes.four)}
            width={lImage}
            height={lImage}
            alt='number'
          /> */}
          <img
            style={{ marginRight: 2 }}
            src={require('./assets/images/' + twoPrizes.five)}
            width={lImage}
            height={lImage}
            alt='number'
          />
          <img
            src={require('./assets/images/' + twoPrizes.six)}
            width={lImage}
            height={lImage}
            alt='number'
          />
        </div>

        <div className="d-flex justify-content-center">
          <div className="card" style={{ minWidth: isMobile ? 100 : 150 }}>
            <div className="card-title">
              <span>3 ตัวบน</span>
            </div>
            <div className="card-body">
              <p className="card-text">{upThree}</p>
            </div>
          </div>
          <div className="card" style={{ minWidth: isMobile ? 100 : 150 }}>
            <div className="card-title">
              <span>2 ตัวบน</span>
            </div>
            <div className="card-body">
              <p className="card-text">{upTwo}</p>
            </div>
          </div>
          <div className="card" style={{ minWidth: isMobile ? 100 : 150 }}>
            <div className="card-title">
              <span>2 ตัวล่าง</span>
            </div>
            <div className="card-body">
              <p className="card-text">{downTwo}</p>
            </div>
          </div>
        </div>

        <h3 style={{ color: 'white', marginTop: 15, marginBottom: 0, fontSize: 22, fontWeight: 'bold' }}><b>สถิติย้อนหลัง หวยไทยเฉพาะกิจ</b></h3>
        <div className="statistic-content">
          <table className="table table-bordered rounded-3">
            <thead>
              <tr>
                <th scope="col">7 งวดล่าสุด</th>
                <th scope="col">3 ตัวบน</th>
                <th scope="col">2 ตัวบน</th>
                <th scope="col">2 ตัวล่าง</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.statistics.map((v, index) => {
                  if (index < 7) {
                    return <tr key={index + v.dateAnnounce}>
                      <th>{moment(v.dateAnnounce).format("DD-MM-YYYY")}</th>
                      <td>{v.up3}</td>
                      <td>{v.up2}</td>
                      <td>{v.down2}</td>
                    </tr>
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default App;
